var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal is-active"},[_c('div',{staticClass:"modal-card-body has-background-info",style:({
      backgroundImage: 'url(' + require('@/assets/bg-world.svg') + ')',
      backgroundSize: 'cover'
    })},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"title is-4 menu-item"},[_vm._v("Menu")]),_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"column is-12"},[_c(item.view ? 'a' : 'router-link',{tag:"component",staticClass:"subtitle has-text-white is-underlined",attrs:{"to":item.route
                ? {
                    name: item.route,
                    params: { vrm: _vm.vrm }
                  }
                : false},on:{"click":function($event){return _vm.$emit('view-change', item.view)}}},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),0),_c('p',{staticClass:"title is-4 has-text-white menu-item"},[_vm._v("Search again")]),_c('CompanionVehicleSearch',{on:{"success":function($event){return _vm.$emit('close')}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }