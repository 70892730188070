<template>
  <div class="modal is-active">
    <div
      class="modal-card-body has-background-info"
      :style="{
        backgroundImage: 'url(' + require('@/assets/bg-world.svg') + ')',
        backgroundSize: 'cover'
      }"
    >
      <div class="container">
        <p class="title is-4 menu-item">Menu</p>
        <div class="columns is-multiline">
          <div class="column is-12" v-for="(item, index) in items" :key="index">
            <component
              class="subtitle has-text-white is-underlined"
              :is="item.view ? 'a' : 'router-link'"
              :to="
                item.route
                  ? {
                      name: item.route,
                      params: { vrm }
                    }
                  : false
              "
              @click="$emit('view-change', item.view)"
            >
              {{ item.label }}
            </component>
          </div>
        </div>
        <p class="title is-4 has-text-white menu-item">Search again</p>
        <CompanionVehicleSearch @success="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import noScroll from 'no-scroll'
import { mapGetters } from 'vuex'
export default {
  name: 'CompanionMenu',
  components: {
    CompanionVehicleSearch: () =>
      import('modules/layout/CompanionVehicleSearch')
  },
  mounted: () => noScroll.on(),
  destroyed: () => noScroll.off(),
  watch: {
    modalIsActive() {
      return noScroll.on()
    }
  },
  computed: {
    items: () => [
      {
        label: 'Valuation information',
        view: 'overview'
      },
      { label: 'Technical specs', view: 'specs' },
      { label: 'Timeline and listings', view: 'listings' }
    ],
    classActive: () => 'is-active',
    ...mapGetters('companion', ['vrm'])
  }
}
</script>

<style lang="sass" scoped>
.is-underlined
  border-bottom: 1px solid currentColor
</style>
